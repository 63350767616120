import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import apiClient from "../../api.config";
import secureLocalStorage from "react-secure-storage";

export function ChangePasswordForm() {
  const userData = secureLocalStorage.getItem("UserData");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  async function updatePasswordHandler() {
    const prefixURL = process.env.REACT_APP_API_URL;

    if (!oldPassword && !newPassword && !confirmPassword) {
      toast.error("Please Enter Old Password and New Password");
    } else if (!oldPassword) {
      toast.error("Please Enter Old Password");
    } else if (!newPassword) {
      toast.error("Please Enter New Password");
    } else if (!confirmPassword) {
      toast.error("Please Enter Confirm Password");
    } else if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      try {
        const response = await toast.promise(
          apiClient.post(
            `${prefixURL}/auth/change`,
            {
              userID: userData.userID,
              oldPassword: oldPassword,
              newPassword: confirmPassword,
            },
            {
              withCredentials: true,
              credentials: "same-origin",
            }
          ),
          {
            pending: "Please wait...",
          }
        );

        if (response.data.status === true && response.data.error === null) {
          toast.success("Successfully Updated Password");
          setTimeout(() => {
            navigate("/login");
          }, 4000);
        } else {
          toast.error("Something went wrong while resetting");
        }
      } catch (error) {
        if (error.response?.status === 403) {
          toast.error(`${error.response.data.content.message}`);
        } else {
          toast.error(`${error.message}`);
        }
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Edit Password | Play Fund Win</title>
        <meta name="description" content="We are Play Fund Win, the #1 digital fundraising platform that makes raising money simple for sports clubs, events, and charities." />
      </Helmet>
      <div className="min-h-screen flex flex-col justify-left items-left py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="my-4">
              <input
                type="password"
                onChange={(event) => setOldPassword(event.target.value)}
                placeholder="Enter Old Password"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 sm:text-sm"
              />
            </div>
            <div className="my-4">
              <input
                type="password"
                onChange={(event) => setNewPassword(event.target.value)}
                placeholder="Enter New Password"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 sm:text-sm"
              />
            </div>
            <div className="my-4">
              <input
                type="password"
                onChange={(event) => setConfirmPassword(event.target.value)}
                placeholder="Confirm New Password"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <button
              onClick={updatePasswordHandler}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default ChangePasswordForm;
