// Account.jsx
import React from "react";
import DashboardWrapper from "../components/dashboard/Dashboard"; // Adjust the path as needed
// import DashboardWrapper from "@pfw/shared_components/DashboardWrapper";

const Account = () => {
  return (
    <div>
      <DashboardWrapper />
    </div>
  );
};

export default Account;
