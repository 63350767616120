import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import creditCardType from "credit-card-type";
import { Helmet } from "react-helmet";
import { PurchaseHistory } from "../components/PurchaseHistory";
import AddNewCardModal from "../components/AddNewCardModal";
import EditCardModal from "../components/EditCardModal";
import DeleteCardModal from "../components/DeleteCardModal";
import SetDefaultCardModal from "../components/SetDefaultCardModal";
import { useDashboard } from "../DashboardProvider";

let header = { withCredentials: true, credentials: "same-origin" };
const luhn = require("luhn");

export default function MyPayments() {
  const navigate = useNavigate();
  const [isLoaded, setLoadComplete] = useState(false);
  const [PurchaseData, setPurchaseData] = useState([]);
  const secureUserData = secureLocalStorage.getItem("UserData");

  const siteID = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  const [userPaymentMethodList, SetUserPaymentMethodList] = useState([]);
  const [paymentMethodChange, SetPaymentMethodChange] = useState(1);
  const [modalAddNewCard, SetModalAddNewCard] = useState(false);
  const [modalEditCard, SetModalEditCard] = useState(false);
  const [modalDeleteCard, SetModalDeleteCard] = useState(false);
  const [modalDefaultCard, SetModalDefaultCard] = useState(false);

  // selected_card
  const [selectedCard, SetSelectedCard] = useState(null);

  // add new card
  const [cardNumber, setCardNumber] = useState("");
  const [cardCVC, setCardCVC] = useState("");
  const [cardExpiryDate, setCardExpiryDate] = useState("");
  const [clickPaymentMethodBtn, setClickPaymentMethodBtn] = useState(false);
  const [cardBrandLogo, setCardBrandLogo] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userSurname, setUserSurname] = useState("");

  useEffect(() => {
    if (!secureUserData) {
      navigate("/login");
    } else {
      axios
        .get(`${APIURL}/users/${secureUserData.userID}/data`, {
          withCredentials: true,
          credentials: "same-origin",
        })
        .then((res) => {
          setUserFirstName(res.data.content?.userFirstName);
          setUserSurname(res.data.content?.userSurname);
        });
    }
  }, [secureUserData, setUserFirstName, setUserSurname, APIURL, navigate]);

  const handleCardNumberChange = (event) => {
    let value = event.target.value.replace(/\D/g, "");
    value = value.replace(/(\d{4})(?=\d)/g, "$1 ");
    setCardNumber(value);

    if (!value) {
      setCardBrandLogo("");
    } else {
      const cardType = creditCardType(value);
      if (cardType && cardType.length > 0) {
        const cardBrand = cardType[0].type.toLowerCase();
        setCardBrandLogo(cardBrand);
      } else {
        setCardBrandLogo("");
      }
    }
  };

  const handleCardCVCChange = (event) => {
    setCardCVC(event.target.value);
  };

  function handleCardExpiryDateChange(event) {
    const { value } = event.target;
    let formattedValue = value.replace(/\D/g, "").slice(0, 4);
    if (formattedValue.length > 2) {
      formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2, 4)}`;
    }
    setCardExpiryDate(formattedValue);
  }

  async function handleAddUserPaymentMethod(event) {
    if (!luhn.validate(cardNumber) || cardNumber === "") {
      toast.error(`Invalid card number`);
      return;
    }

    if (cardExpiryDate.length === 0) {
      toast.error(`Invalid expiry date`);
      return;
    }

    let expYear = parseInt("20" + cardExpiryDate.split("/")[1]);
    let expMonth = parseInt(cardExpiryDate.split("/")[0]);

    if (parseInt(expYear) < new Date().getFullYear()) {
      toast.error(`Invalid expiry Year`);
      return;
    }

    const expDate = new Date(`${expYear}-${expMonth}-01`);
    const currentDate = new Date();
    if (expDate < currentDate || expMonth > 12 || parseInt(expMonth) === 0) {
      toast.error(`Card is expired`);
      return;
    }

    const cvcRegex = /^[0-9]{3,4}$/;
    if (!cvcRegex.test(cardCVC)) {
      toast.error(`Invalid CVC`);
      return;
    }

    let body = {
      userID: secureUserData.userID,
      action: "addPaymentMethod",
      cardNumber: cardNumber,
      cardCVC: cardCVC,
      expMonth: cardExpiryDate.split("/")[0],
      expYear: cardExpiryDate.split("/")[1],
    };

    setClickPaymentMethodBtn(true);
    let res = await actionUserPaymentMethod(body);
    setClickPaymentMethodBtn(false);

    if (res.data?.status) {
      toast.success(res.data.content.message);
      updatePaymentMethod();
      SetModalAddNewCard(false);
    } else {
      toast.error(res.response?.data?.error?.msg || "Unknown Error!");
    }
  }

  async function handleEditUserPaymentMethod(event) {
    let expYear = parseInt("20" + cardExpiryDate.split("/")[1]);
    let expMonth = parseInt(cardExpiryDate.split("/")[0]);

    if (cardExpiryDate.length === 0) {
      toast.error(`Invalid expiry date`);
      return;
    }

    if (parseInt(expYear) < new Date().getFullYear()) {
      toast.error(`Invalid expiry Year`);
      return;
    }

    const expDate = new Date(`${expYear}-${expMonth}-01`);
    const currentDate = new Date();
    if (expDate < currentDate || expMonth > 12 || expMonth === 0) {
      toast.error(`Card is expired`);
      return;
    }

    let body = {
      userID: secureUserData.userID,
      paymentMethodID: selectedCard.paymentMethodID,
      action: "editPaymentMethod",
      expMonth: expMonth,
      expYear: expYear,
    };

    setClickPaymentMethodBtn(true);
    let res = await actionUserPaymentMethod(body);
    setClickPaymentMethodBtn(false);

    if (res.data?.status) {
      toast.success(res.data.content.message);
      updatePaymentMethod();
      SetModalEditCard(false);
    } else {
      toast.error(res.response?.data?.error?.msg || "Unknown Error!");
    }
  }

  async function handleDeleteUserPaymentMethod(event) {
    let body = {
      userID: secureUserData.userID,
      paymentMethodID: selectedCard.paymentMethodID,
      action: "deletePaymentMethod",
    };

    setClickPaymentMethodBtn(true);
    let res = await actionUserPaymentMethod(body);
    setClickPaymentMethodBtn(false);

    if (res.data?.status) {
      toast.success(res.data.content.message);
      updatePaymentMethod();
      SetModalDeleteCard(false);
    } else {
      toast.error(res.response?.data?.error?.msg || "Unknown Error!");
    }
  }

  async function handleDefaultUserPaymentMethod(event) {
    let body = {
      userID: secureUserData.userID,
      paymentMethodID: selectedCard.paymentMethodID,
      action: "setDefaultPaymentMethod",
    };

    setClickPaymentMethodBtn(true);
    let res = await actionUserPaymentMethod(body);
    setClickPaymentMethodBtn(false);

    if (res.data?.status) {
      toast.success(res.data.content.message);
      updatePaymentMethod();
      SetModalDefaultCard(false);
    } else {
      toast.error(res.response?.data?.error?.msg || "Unknown Error!");
    }
  }

  function updatePaymentMethod() {
    SetPaymentMethodChange(paymentMethodChange + 1);
  }

  async function actionUserPaymentMethod(body) {
    try {
      const res = await axios.post(APIURL + `/users/cardInfo`, body, header);
      return res;
    } catch (err) {
      console.log("result error:", err);
      return err;
    }
  }

  async function getUserPaymentMethodList(body) {
    try {
      const res = await axios.post(APIURL + `/users/cardInfo`, body, header);

      if (res.data?.content?.status === 200) {
        let payment_method_list = [];
        if (Array.isArray(res.data?.content?.payment_method_list?.data)) {
          let default_payment_method = null;
          for (let i = 0; i < res.data.content.payment_method_list.data.length; i++) {
            let payment_method = {
              paymentMethodID: res.data.content.payment_method_list.data[i].id,
              cardBrand: res.data.content.payment_method_list.data[i].card.brand,
              cardNumber: "XXXXXXXXXXXX" + res.data.content.payment_method_list.data[i].card.last4,
              expMonth: res.data.content.payment_method_list.data[i].card.exp_month,
              expYear: res.data.content.payment_method_list.data[i].card.exp_year,
              last4: res.data.content.payment_method_list.data[i].card.last4,
              obj: res.data.content.payment_method_list.data[i],
            };

            if (payment_method.paymentMethodID === res.data.content.default_payment_method_id) {
              default_payment_method = payment_method;
            } else {
              payment_method_list.push(payment_method);
            }
          }
          if (!res.data.content.default_payment_method_id) {
            let payment_method = {
              paymentMethodID: null,
              cardBrand: "____",
              cardNumber: "No default",
              expMonth: "XX",
              expYear: "XXXX",
              last4: "XXXX",
              obj: null,
            };
            payment_method_list.unshift(payment_method);
          } else {
            payment_method_list.unshift(default_payment_method);
          }
        }
        return payment_method_list;
      }

      return [];
    } catch (err) {
      console.log("result error:", err);
      return err;
    }
  }

  useEffect(() => {
    if (!secureUserData) {
      navigate("/login");
    } else {
      getUserPaymentMethodList({ userID: secureUserData.userID, action: "getPaymentMethods" }).then((res) => {
        if (Array.isArray(res)) {
          SetUserPaymentMethodList(res);
        } else {
          SetUserPaymentMethodList([]);
        }
        setLoadComplete(true);
      });
    }
  }, [paymentMethodChange]);

  return (
    <>
      <Helmet>
        <title>Billing & Payment | Secure Transactions with Hendrix Archive Draw</title>
        <meta name="description" content="Effortlessly manage your billing details and transactions, knowing your information is handled with utmost security." />
      </Helmet>
      <ToastContainer />
      <AddNewCardModal
        open={modalAddNewCard}
        setOpen={SetModalAddNewCard}
        handleAddUserPaymentMethod={handleAddUserPaymentMethod}
        cardNumber={cardNumber}
        handleCardNumberChange={handleCardNumberChange}
        cardCVC={cardCVC}
        handleCardCVCChange={handleCardCVCChange}
        cardExpiryDate={cardExpiryDate}
        handleCardExpiryDateChange={handleCardExpiryDateChange}
        cardBrandLogo={cardBrandLogo}
        clickPaymentMethodBtn={clickPaymentMethodBtn}
      />
      {selectedCard && (
        <EditCardModal
          open={modalEditCard}
          setOpen={SetModalEditCard}
          handleEditUserPaymentMethod={handleEditUserPaymentMethod}
          cardNumber={cardNumber}
          handleCardNumberChange={handleCardNumberChange}
          cardCVC={cardCVC}
          handleCardCVCChange={handleCardCVCChange}
          cardExpiryDate={cardExpiryDate}
          handleCardExpiryDateChange={handleCardExpiryDateChange}
          cardBrandLogo={cardBrandLogo}
          clickPaymentMethodBtn={clickPaymentMethodBtn}
        />
      )}
      {selectedCard && (
        <DeleteCardModal
          open={modalDeleteCard}
          setOpen={SetModalDeleteCard}
          handleDeleteUserPaymentMethod={handleDeleteUserPaymentMethod}
          clickPaymentMethodBtn={clickPaymentMethodBtn}
        />
      )}
      {selectedCard && (
        <SetDefaultCardModal
          open={modalDefaultCard}
          setOpen={SetModalDefaultCard}
          handleDefaultUserPaymentMethod={handleDefaultUserPaymentMethod}
          clickPaymentMethodBtn={clickPaymentMethodBtn}
        />
      )}
      <div className="space-y-12">
        <div className="text-left pb-4">
          <h1 className="text-3xl font-bold leading-9 text-gray-900">
            <strong className="font-semibold">My Payments</strong>
          </h1>
        </div>
        <div className="border-b border-gray-900/10 pb-12">
          <PurchaseHistory />
        </div>
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Existing Payment Methods</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Manage your existing payment methods.</p>

          <div className="mt-10 space-y-6">
            {isLoaded ? (
              userPaymentMethodList.length === 0 ? (
                <div className="text-center">
                  <p className="text-sm text-gray-500">There are no payment methods. Please add a new one.</p>
                </div>
              ) : (
                userPaymentMethodList.map((row, index) => (
                  <div key={index} className="relative rounded-md bg-white p-4 shadow-sm ring-1 ring-gray-200">
                    <div className="flex items-center justify-between">
                      <div>
                        <h4 className="text-sm font-semibold text-gray-900">XXXX XXXX XXXX {row.last4}</h4>
                        <p className="text-sm text-gray-500">
                          {row.expMonth}/{row.expYear}
                        </p>
                      </div>
                      <div className="flex space-x-3">
                        {index !== 0 && (
                          <button
                            onClick={() => {
                              SetSelectedCard(row);
                              SetModalDefaultCard(true);
                            }}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Set Default
                          </button>
                        )}
                        <button
                          onClick={() => {
                            SetSelectedCard(row);
                            SetModalEditCard(true);
                          }}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => {
                            SetSelectedCard(row);
                            SetModalDeleteCard(true);
                          }}
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )
            ) : (
              <div className="text-center">
                <p className="text-sm text-gray-500">Loading...</p>
              </div>
            )}
          </div>
        </div>
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Payment Methods</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Manage your payment methods securely.</p>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-1/4">
              <button
                type="button"
                onClick={() => {
                  setCardNumber("");
                  setCardExpiryDate("");
                  setCardCVC("");
                  SetModalAddNewCard(true);
                }}
                className="mt-6 w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add A New Card
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
