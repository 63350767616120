import { Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DashboardContext } from "../DashboardProvider";

export const EditSubscriptionModal = ({ open, setOpen, handleEditDraw, handleQuantityChange }) => {
  const { clickProcessBtn, editQuantity, selectDraw } = useContext(DashboardContext);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Edit Draw Subscription
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="d-flex flex-column justify-content-start">
                        <div className="">
                          <label>Price:</label> <strong className="fs-5">£{selectDraw.userSubscriptionPrice}</strong>
                        </div>
                        <div className="mt-3 d-flex flex-column">
                          <label className="mb-2" style={{ width: "70px" }}>
                            Quantity:
                          </label>
                          <input
                            value={editQuantity}
                            onChange={handleQuantityChange}
                            onClick={(e) => e.preventDefault()}
                            placeholder="quantity"
                            type="number"
                            maxLength={10}
                            className="rounded-md border border-gray-300 p-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 sm:mt-0 sm:w-auto sm:text-sm"
                    disabled={clickProcessBtn}
                    onClick={handleEditDraw}
                  >
                    Yes
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditSubscriptionModal;
