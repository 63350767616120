// MyAccount.jsx
import React, { useEffect, useState } from "react";
import DashboardHeader from "../DashboardHeader";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import apiClient from "../../../api.config";
import useDocumentTitle from "../../useDocumentTitle";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Helmet } from "react-helmet";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { useSiteData } from "../../../context/SiteDataProvider";

export default function MyAccount() {
  useDocumentTitle("Edit Profile");
  const navigate = useNavigate();
  const [isLoaded, setLoadComplete] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userFirstName, setFirstName] = useState("");
  const [userLastName, setLastName] = useState("");
  const [userAddreess1, setAddress1] = useState("");
  const [userAddreess2, setAddress2] = useState("");
  const [userPostcode, setUserPostcode] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [defaultData, setDefaultData] = useState();
  const [userFetch, setUserFetch] = useState();
  const secureUserData = secureLocalStorage.getItem("UserData");

  const APIURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/users/${secureUserData.userID}/data`, {
          withCredentials: true,
          credentials: "same-origin",
        })
        .then((res) => {
          setUserFetch(res.data.content);
          setUserEmail(res.data.content.userEmail);
          setFirstName(res.data.content.userFirstName);
          setLastName(res.data.content.userSurname);
          setAddress1(res.data.content.userAddressLine1);
          setAddress2(res.data.content.userAddressLine2);
          setUserPostcode(res.data.content.userAddressPostcode);
          setUserPhoneNumber(res.data.content.userPhoneNumber);
          setLoadComplete(true);
          setDefaultData({
            userEmail: res.data.content.userEmail,
            userFirstName: res.data.content.userFirstName,
            userSurname: res.data.content.userSurname,
            userAddressLine1: res.data.content.userAddressLine1,
            userAddressLine2: res.data.content.userAddressLine2,
            userAddressPostcode: res.data.content.userAddressPostcode,
            userPhoneNumber: res.data.content.userPhoneNumber,
          });
        })
        .catch((error) => {
          console.error(error);
          toast("Please try again later.");
        });
    } catch (error) {
      console.error(error);
      toast("Please try again later.");
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let fieldsToUpdate = { userID: userFetch.userID };

    if (userEmail !== defaultData.userEmail) {
      if (!userEmail) {
        toast.warn("Email is important field");
        return;
      }
      fieldsToUpdate.userEmail = userEmail;
    }
    if (userFirstName !== defaultData.userFirstName) {
      if (!userFirstName) {
        toast.warn("First Name is important field");
        return;
      }
      fieldsToUpdate.userFirstName = userFirstName;
    }
    if (userLastName !== defaultData.userSurname) {
      if (!userLastName) {
        toast.warn("Last Name is important field");
        return;
      }
      fieldsToUpdate.userSurname = userLastName;
    }
    if (userAddreess1 !== defaultData.userAddressLine1) {
      if (!userAddreess1) {
        toast.warn("Address is important field");
        return;
      }
      fieldsToUpdate.userAddressLine1 = userAddreess1;
    }
    if (userAddreess2 !== defaultData.userAddressLine2) {
      fieldsToUpdate.userAddressLine2 = userAddreess2;
    }
    if (userPostcode !== defaultData.userAddressPostcode) {
      if (!userPostcode) {
        toast.warn("Address Postcode is important field");
        return;
      }
      fieldsToUpdate.userAddressPostcode = userPostcode;
    }
    if (userPhoneNumber !== defaultData.userPhoneNumber) {
      if (userPhoneNumber == undefined || userPhoneNumber?.length < 5) {
        toast.warn("Phone number is important field");
        return;
      } else {
        console.log("UserPhone", userPhoneNumber);
        fieldsToUpdate.userPhoneNumber = userPhoneNumber;
      }
    }
    try {
      await toast.promise(
        apiClient.put(`${process.env.REACT_APP_API_URL}/users`, fieldsToUpdate, {
          withCredentials: true,
          credentials: "same-origin",
        }),
        {
          pending: "Please wait...",
          success: "Update Successfully",
        }
      );
    } catch (error) {
      console.log(error?.response);
      console.log(error?.message);
      if (error?.response?.data?.error?.msg) {
        toast.error(error?.response?.data?.error?.msg);
      } else {
        toast.error(error?.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Account Settings | Tailor Your Hendrix Archive Draw Experience</title>
        <meta name="description" content="Fine-tune your account settings for a personalized, hassle-free experience as you contribute to a cause and aim for exciting prizes." />
      </Helmet>

      {isLoaded ? (
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            {/* <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">This information will be displayed publicly so be careful what you share.</p>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                    Username
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">workcation.com/</span>
                      <input
                        type="text"
                        name="username"
                        id="username"
                        autoComplete="username"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="janesmith"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    About
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="about"
                      name="about"
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={""}
                    />
                  </div>
                  <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>
                </div>

                <div className="col-span-full">
                  <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                    Photo
                  </label>
                  <div className="mt-2 flex items-center gap-x-3">
                    <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />
                    <button
                      type="button"
                      className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Change
                    </button>
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                    Cover photo
                  </label>
                  <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                    <div className="text-center">
                      <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                      <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Upload a file</span>
                          <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="text-left pb-4">
              <h1 className="text-3xl font-bold leading-9 text-gray-900">
                <strong className="font-semibold">My Account</strong>
              </h1>
            </div>
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                    First name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={userFirstName}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Last name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={userLastName}
                      onChange={(event) => setLastName(event.target.value)}
                    />
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={userEmail}
                      onChange={(event) => setUserEmail(event.target.value)}
                    />
                  </div>
                </div>

                {/* Commented out new fields to be added later */}
                <div className="sm:col-span-3">
                  <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                    Country
                  </label>
                  <div className="mt-2">
                    <select
                      id="country"
                      name="country"
                      autoComplete="country-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option>United States</option>
                      <option>Canada</option>
                      <option>Mexico</option>
                    </select>
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="address-line1" className="block text-sm font-medium leading-6 text-gray-900">
                    Address Line 1
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="address-line1"
                      id="address-line1"
                      autoComplete="address-line1"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={userAddreess1}
                      onChange={(event) => setAddress1(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label htmlFor="address-line2" className="block text-sm font-medium leading-6 text-gray-900">
                    Address Line 2
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="address-line2"
                      id="address-line2"
                      autoComplete="address-line2"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={userAddreess2}
                      onChange={(event) => setAddress2(event.target.value)}
                    />
                  </div>
                </div>

                {/* <div className="sm:col-span-2 sm:col-start-1">
                  <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                    City
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      // value={userCity}
                      // onChange={(event) => setUserCity(event.target.value)}
                    />
                  </div>
                </div> */}

                <div className="sm:col-span-2">
                  <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                    State / Province
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="region"
                      id="region"
                      autoComplete="address-level1"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      // value={userState}
                      // onChange={(event) => setUserState(event.target.value)}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                    ZIP / Postal code
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="postal-code"
                      id="postal-code"
                      autoComplete="postal-code"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={userPostcode}
                      onChange={(event) => setUserPostcode(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Notifications</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">We'll always let you know about important changes, but you pick what else you want to hear about.</p>

              <div className="mt-10 space-y-10">
                <fieldset>
                  <legend className="text-sm font-semibold leading-6 text-gray-900">By Email</legend>
                  <div className="mt-6 space-y-6">
                    <div className="relative flex gap-x-3">
                      <div className="flex h-6 items-center">
                        <input id="comments" name="comments" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                      </div>
                      <div className="text-sm leading-6">
                        <label htmlFor="comments" className="font-medium text-gray-900">
                          Comments
                        </label>
                        <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p>
                      </div>
                    </div>
                    <div className="relative flex gap-x-3">
                      <div className="flex h-6 items-center">
                        <input id="candidates" name="candidates" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                      </div>
                      <div className="text-sm leading-6">
                        <label htmlFor="candidates" className="font-medium text-gray-900">
                          Candidates
                        </label>
                        <p className="text-gray-500">Get notified when a candidate applies for a job.</p>
                      </div>
                    </div>
                    <div className="relative flex gap-x-3">
                      <div className="flex h-6 items-center">
                        <input id="offers" name="offers" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                      </div>
                      <div className="text-sm leading-6">
                        <label htmlFor="offers" className="font-medium text-gray-900">
                          Offers
                        </label>
                        <p className="text-gray-500">Get notified when a candidate accepts or rejects an offer.</p>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend className="text-sm font-semibold leading-6 text-gray-900">Push Notifications</legend>
                  <p className="mt-1 text-sm leading-6 text-gray-600">These are delivered via SMS to your mobile phone.</p>
                  <div className="mt-6 space-y-6">
                    <div className="flex items-center gap-x-3">
                      <input id="push-everything" name="push-notifications" type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                      <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                        Everything
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3">
                      <input id="push-email" name="push-notifications" type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                      <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                        Same as email
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3">
                      <input id="push-nothing" name="push-notifications" type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                      <label htmlFor="push-nothing" className="block text-sm font-medium leading-6 text-gray-900">
                        No push notifications
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </div>
        </form>
      ) : (
        <div className="user-subscription-section font-face-sh pb-5">
          <div className="no-subscription-box bg-white rounded-3 p-4 mx-auto" style={{ maxWidth: 327, boxShadow: "0 4px 28px rgba(0,0,0,.08)" }}>
            <p className="text-center fs-14 m-0" style={{ color: "#302d51" }}>
              <strong>Loading...</strong>
            </p>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
}
