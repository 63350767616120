import React, { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import apiClient from "../../api.config";
import { useSiteData } from "../../context/SiteDataProvider";
// Create the context
const DashboardContext = createContext();
// Create the provider component
const DashboardProvider = ({ children }) => {
  const navigate = useNavigate();
  const APIURL = process.env.REACT_APP_API_URL;
  const siteID = process.env.REACT_APP_API_SITE_ID;
  const userData = secureLocalStorage.getItem("UserData");
  const { isLoggedIn, setIsLoggedIn, userFirstName, setUserFirstName, userSurname, setUserSurname } = useSiteData();

  const [isLoaded, setLoadComplete] = useState(false);
  const [page, setPage] = useState(1);
  const [prizeData, setprizeData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow4, setModalShow4] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editModalShow2, setEditModalShow2] = useState(false);
  const [fullname, setFullName] = useState("");
  const [accountnumber, setAccountNumber] = useState("");
  const [emailUser, setEmailUser] = useState("");
  const [sortcode, setSortCode] = useState("");
  const [paypalemail, setPaypalEmail] = useState("");
  const [bankaccount, setBankAccount] = useState(true);
  const [prizeID, setPrizeID] = useState("");
  const [paypal, setPaypal] = useState(false);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [postcode, setPostcode] = useState("");

  const [inc, setInc] = useState(0);
  const [userSubscriptionList, setUserSubscriptionList] = useState([]);
  const [subscriptioniChange, setSubscriptionChange] = useState(1);
  const [selectHost, setSelectHost] = useState(null);
  const [selectDraw, setSelectDraw] = useState(null);
  const [showDetails, setShowDetails] = useState({});
  const [clickProcessBtn, setClickProcessBtn] = useState(false);
  const [editQuantity, setEditQuantity] = useState(1);

  useEffect(() => {
    const getUserSubscriptionList = async (userID) => {
      try {
        const res = await axios.get(`${APIURL}/users/${userID}/subscription`, { withCredentials: true });
        let host_list = [];
        if (Array.isArray(res.data?.content)) {
          for (let i = 0; i < res.data.content.length; i++) {
            let selected_host = null;
            for (let iHost = 0; iHost < host_list.length; iHost++) {
              if (host_list[iHost].hostID === res.data.content[i].userSubscriptionHostID) {
                selected_host = host_list[iHost];
                break;
              }
            }
            if (selected_host == null) {
              selected_host = {
                hostName: res.data.content[i].hostName,
                hostID: res.data.content[i].userSubscriptionHostID,
                amount: 0,
                subscriptionType: "Monthly",
                active: [],
                cancel: [],
                other: [],
              };
              host_list.push(selected_host);
            }
            if (res.data.content[i].userSubscriptionStatusID === 3) {
              selected_host.active.push(res.data.content[i]);
              selected_host.amount += res.data.content[i].userSubscriptionAmount;
            } else if (res.data.content[i].userSubscriptionStatusID === 6) {
              selected_host.cancel.push(res.data.content[i]);
            } else {
              selected_host.other.push(res.data.content[i]);
            }
          }
          setUserSubscriptionList(host_list);
        } else {
          setUserSubscriptionList([]);
        }
      } catch (err) {
        console.error("Error fetching user subscription list:", err);
      }
    };
    console.log(userData      );
    if (!userData) {
      navigate("/login");
    } else {
      let mergingArray = [];
      axios
        .get(`${process.env.REACT_APP_API_URL}/users/${userData.userID}/data`, {
          withCredentials: true,
          credentials: "same-origin",
        })
        .then((res) => {
          const user = {
            userFirstName: res.data.content?.userFirstName,
            userSurname: res.data.content?.userSurname,
            userEmail: res.data.content?.userEmail,
          };

          setUserFirstName(user.userFirstName);
          setUserSurname(user.userSurname);
          setEmailUser(user.userEmail);

          if (res.data.content.prizes) {
            mergingArray.push(res.data.content.prizes);
          }

          axios
            .get(`${process.env.REACT_APP_API_URL}/prizes/getClaim/${userData.userID}/`, {
              withCredentials: true,
              credentials: "same-origin",
            })
            .then((innerRes) => {
              if (innerRes.data.content.length) {
                res.data.content.prizes.forEach((el) => {
                  let details = innerRes.data.content.find((item) => item.prize_id === el.prizeID);
                  el.prizeClaim = details;
                });
                mergingArray.push(res.data.content.prizes);
              }
            })
            .then(() => {
              const uniqueArray = mergingArray
                .flat(Infinity)
                .filter((item, index, self) => index === self.findIndex((t) => t.drawID === item.drawID && t.prizeID === item.prizeID));
              setprizeData(uniqueArray.flat(Infinity).sort((a, b) => b.id - a.id));
              setLoadComplete(true);
            });
        });
      getUserSubscriptionList(userData.userID);
    }
  }, [inc, userData, navigate, subscriptioniChange, clickProcessBtn, APIURL, setUserFirstName, setUserSurname]);

  const ClaimCashPrize = async () => {
    if (!bankaccount && paypalemail === "") {
      toast.error(`Please Enter Your Proper paypal email`);
      return;
    } else if (bankaccount && fullname === "" && accountnumber === "" && sortcode === "") {
      toast.error(`Please Enter Bank Details`);
      return;
    }

    try {
      const response = await toast.promise(
        apiClient.post(
          `${process.env.REACT_APP_API_URL}/prizes/claim`,
          {
            userID: userData.userID,
            prize_id: prizeID,
            PaymentMethod: bankaccount ? "bank" : "paypal",
            full_name: fullname !== "" ? fullname : "paypal",
            Sortcode: sortcode !== "" ? sortcode : "paypal",
            account_no: accountnumber !== "" ? accountnumber : "paypal",
            userEmail: paypalemail !== "" ? paypalemail : "bank",
            detail_meta: { siteID: siteID },
            datameta: { email: secureLocalStorage.getItem("UserData").email },
            pageType: "Cash prize",
          },
          {
            withCredentials: true,
            credentials: "same-origin",
          }
        ),
        {
          pending: "Please wait...",
        }
      );

      if (response.data.status === true && response.data.error === null && response.data.content === null) {
        setModalShow(false);
        toast("Thank you for submitting, Your prize will be processed in the next 14 - 21 days", { type: "success" });
      } else if (response.data.status === true && response.data.content.message === "Already Claimed") {
        setModalShow(false);
        toast("Prize already claimed!", { type: "info" });
      } else {
        setModalShow(false);
        toast("Something went wrong while processing your request", { type: "error" });
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
    setModalShow4(false);
    setModalShow3(false);
    setInc((prev) => prev + 1);
  };

  const ClaimNonCashPrize = async () => {
    if (address1 === "" || address2 === "" || postcode === "") {
      toast.error(`Please Enter Your full Address`);
      return;
    }

    try {
      const details_meta = { siteID: siteID, address1, address2, postcode };

      const response = await toast.promise(
        apiClient.post(
          `${process.env.REACT_APP_API_URL}/prizes/claim`,
          {
            userID: userData.userID,
            prize_id: prizeID,
            PaymentMethod: "prize",
            full_name: `${userFirstName} ${userSurname}`,
            Sortcode: "-",
            account_no: "-",
            emails: emailUser,
            userEmail: "-",
            detail_meta: details_meta,
            datameta: { email: secureLocalStorage.getItem("UserData").email },
            pageType: "Prize",
          },
          {
            withCredentials: true,
            credentials: "same-origin",
          }
        ),
        {
          pending: "Please wait...",
        }
      );

      if (response.data.status === true && response.data.error === null && response.data.content === null) {
        setModalShow2(false);
        toast("Thank you for submitting, Your prize will be processed in the next 14 - 21 days", { type: "success" });
      } else if (response.data.status === true && response.data.content.message === "Already Claimed") {
        setModalShow2(false);
        toast("Prize already claimed!", { type: "info" });
      } else {
        setModalShow2(false);
        toast("Something went wrong while processing your request", { type: "error" });
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
    setModalShow4(false);
    setModalShow3(false);
    setInc((prev) => prev + 1);
  };

  const editClaimCashPrizeHandler = async () => {
    if (!bankaccount && paypalemail === "-") {
      toast.error(`Please Enter your paypal email`);
      return;
    } else if (paypal && !isValidEmail(paypalemail)) {
      toast.error(`Please Enter Valid PayPal Email`);
      return;
    } else if (bankaccount && (fullname === "-" || fullname === "") && (accountnumber === "-" || accountnumber === "") && (sortcode === "-" || sortcode === "")) {
      toast.error(`Please Enter Bank Details`);
      return;
    } else if (bankaccount && (fullname === "" || fullname === "-")) {
      toast.error(`Please Enter Full Name`);
      return;
    } else if (bankaccount && (accountnumber === "-" || accountnumber === "")) {
      toast.error(`Please Enter Account Number`);
      return;
    } else if (bankaccount && (sortcode === "-" || sortcode === "")) {
      toast.error(`Please Enter Sortcode`);
      return;
    } else if (isNaN(accountnumber) && accountnumber !== "-") {
      toast.error(`Please Enter Valid Account Number`);
      return;
    } else if (bankaccount && isNaN(sortcode)) {
      toast.error(`Please Enter Valid Sort Code`);
      return;
    }

    try {
      const response = await toast.promise(
        apiClient.put(
          `${process.env.REACT_APP_API_URL}/prizes/updateClaimDetails/${userData.userID}/`,
          {
            userID: userData.userID,
            prize_id: prizeID,
            PaymentMethod: bankaccount ? "bank" : "paypal",
            full_name: fullname !== "" ? fullname : "paypal",
            emails: emailUser,
            Sortcode: sortcode !== "" ? sortcode : "paypal",
            account_no: accountnumber !== "" ? accountnumber : "paypal",
            userEmail: paypalemail !== "" ? paypalemail : "bank",
            detail_meta: { siteID: siteID },
            pageType: "Edit Claim Cash prize",
          },
          {
            withCredentials: true,
            credentials: "same-origin",
          }
        ),
        {
          pending: "Please wait...",
        }
      );

      if (response.data.status === true && response.data.error === null) {
        toast("Claim Details Updated Successfully!", { type: "success" });
        setEditModalShow(false);
        setInc((prev) => prev + 1);
      } else {
        toast("Something went wrong while processing your request", { type: "error" });
        setEditModalShow(false);
        setInc((prev) => prev + 1);
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  const editClaimNonCashPrizeHandler = async () => {
    if (address1 === "" || address2 === "" || postcode === "") {
      toast.error(`Please Enter Your full Address`);
      return;
    }

    const details_meta = { siteID: siteID, address1, address2, postcode };

    try {
      const response = await toast.promise(
        apiClient.put(
          `${process.env.REACT_APP_API_URL}/prizes/updateClaimAddressDetails/${userData.userID}/`,
          {
            userID: userData.userID,
            prize_id: prizeID,
            PaymentMethod: "prize",
            full_name: `${userFirstName} ${userSurname}`,
            Sortcode: "-",
            account_no: "-",
            emails: emailUser,
            userEmail: "-",
            siteID: siteID,
            detail_meta: details_meta,
            pageType: "Edit Claim Prize",
          },
          {
            withCredentials: true,
            credentials: "same-origin",
          }
        ),
        {
          pending: "Please wait...",
        }
      );

      if (response.data.status === true && response.data.error === null) {
        toast("Please Check your registered email inbox to confirm Address change", { type: "success" });
        setEditModalShow2(false);
        setInc((prev) => prev + 1);
      } else {
        toast("Something went wrong while processing your request", { type: "error" });
        setEditModalShow2(false);
        setInc((prev) => prev + 1);
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  function logout() {
    secureLocalStorage.clear();
    axios.delete(`${process.env.REACT_APP_API_URL}/auth/logout`);
    setIsLoggedIn(false);
    navigate("/login");
  }

  return (
    <DashboardContext.Provider
      value={{
        isLoaded,
        isLoggedIn,
        setIsLoggedIn,
        logout,
        page,
        siteID,
        userSubscriptionList,
        setUserSubscriptionList,
        subscriptioniChange,
        setSubscriptionChange,
        editQuantity,
        setEditQuantity,
        selectHost,
        setSelectHost,
        selectDraw,
        setSelectDraw,
        showDetails,
        setShowDetails,
        userData,
        setPage,
        prizeData,
        modalShow,
        setModalShow,
        modalShow2,
        setModalShow2,
        editModalShow,
        setEditModalShow,
        editModalShow2,
        setEditModalShow2,
        clickProcessBtn,
        setClickProcessBtn,
        fullname,
        setFullName,
        accountnumber,
        setAccountNumber,
        emailUser,
        setEmailUser,
        sortcode,
        setSortCode,
        paypalemail,
        setPaypalEmail,
        bankaccount,
        setBankAccount,
        prizeID,
        setPrizeID,
        paypal,
        setPaypal,
        address1,
        setAddress1,
        address2,
        setAddress2,
        postcode,
        setPostcode,
        userSurname,
        setUserSurname,
        userFirstName,
        setUserFirstName,
        modalShow3,
        setModalShow3,
        modalShow4,
        setModalShow4,
        ClaimCashPrize,
        ClaimNonCashPrize,
        editClaimCashPrizeHandler,
        editClaimNonCashPrizeHandler,
        isValidEmail,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
const useDashboard = () => useContext(DashboardContext);

export default DashboardProvider;
export { DashboardProvider, DashboardContext, useDashboard };
