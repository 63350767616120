import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/footer";
import useDocumentTitle from "../components/useDocumentTitle";
import LogoFoundationGray from "../images/mancity-horizontal-dark.png";
import Facebook from "../images/facebook.svg";
import Twitter from "../images/twitter-x-new.svg";
import Instagram from "../images/instagram.svg";
import Visa from "../images/visa.svg";
import Mastercard from "../images/mastercard.svg";
import FooterIconAddress from "../images/footer-icon-address.svg";
import FooterIconEmail from "../images/footer-icon-email.svg";
import bg404 from "../images/404image.png";

export default function PageNotFound() {
  useDocumentTitle("Page Not Found");
  const [login, setIsLoggedIn] = useState(secureLocalStorage.getItem("UserData") ? secureLocalStorage.getItem("UserData").loggedin : false);
  const [DrawData, setDrawData] = useState([]);
  const navigate = useNavigate();

  const siteID = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  function logout() {
    secureLocalStorage.clear();
    axios.delete(APIURL + "/auth/logout");
    setIsLoggedIn(false);
  }

  return (
    <div className="grid min-h-full grid-cols-1 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]">
      <Helmet>
        <title>Win Big in the Hendrix Archive Community Draw | Support CITC and Manchester Community</title>
        <meta
          name="description"
          content="Kick off your winning streak! Join the Hendrix Archive Community Prize Draw to clinch incredible prizes, and play a vital role in supporting Hendrix Archive's enduring mission to uplift communities."
        />
      </Helmet>
      <header className="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8"></header>
      <main className="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
        <div className="max-w-lg">
          <p className="text-base font-semibold leading-8 text-yellow-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-10">
            <a href="/" className="text-sm font-semibold leading-7 text-yellow-600">
              <span aria-hidden="true">&larr;</span> Back to home
            </a>
          </div>
        </div>
      </main>
      <footer className="self-end lg:col-span-2 lg:col-start-1 lg:row-start-3">
        <div className="border-t border-gray-100 bg-gray-50 py-10">
          <nav className="mx-auto flex w-full max-w-7xl items-center gap-x-4 px-6 text-sm leading-7 text-gray-600 lg:px-8">
            <a href="#">Contact support</a>
            <svg viewBox="0 0 2 2" aria-hidden="true" className="h-0.5 w-0.5 fill-gray-300">
              <circle cx={1} cy={1} r={1} />
            </svg>
            <a href="#">Status</a>
          </nav>
        </div>
      </footer>
      <div className="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block">
        <img src={bg404} alt="" className="absolute inset-0 h-full w-full object-cover" />
      </div>
    </div>
  );
}
