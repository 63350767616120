// components/dashboard/DashboardPage1.jsx
import React from "react";
import { UserTickets } from "../components/UserTickets";
const MyTickets = () => {
  return (
    <>
      <UserTickets />
      {/* Add content for Dashboard Page 1 */}
    </>
  );
};

export default MyTickets;
