// src/components/MySubscriptions.jsx
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { DashboardContext } from "../DashboardProvider";

import { CancelHostSubscriptionModal } from "../components/CancelHostSubscriptionModal";
import { CancelDrawSubscriptionModal } from "../components/CancelDrawSubscriptionModal";
import { EditSubscriptionModal } from "../components/EditSubscriptionModal";
import apiClient from "../../../api.config";

export function MySubscriptions() {
  const {
    userData,
    userSubscriptionList,
    subscriptioniChange,
    setSubscriptionChange,
    selectHost,
    setSelectHost,
    selectDraw,
    setSelectDraw,
    showDetails,
    setShowDetails,
    clickProcessBtn,
    setClickProcessBtn,
    editQuantity,
    setEditQuantity,
  } = useContext(DashboardContext);

  const navigate = useNavigate();
  const [modalCancelHost, setModalCancelHost] = useState(false);
  const [modalCancelDraw, setModalCancelDraw] = useState(false);
  const [modalEditDraw, setModalEditDraw] = useState(false);

  useEffect(() => {
    if (!userData) {
      navigate("/login");
    }
  }, [userData, navigate]);

  const handleCancelHost = async () => {
    setClickProcessBtn(true);
    try {
      const res = await apiClient.post(`${process.env.REACT_APP_API_URL}/transactions/stripe/cancelSubscriptionHost`, {
        userID: userData.userID,
        hostID: selectHost.hostID,
      });
      if (res.status) {
        toast.success(`Host Subscription successfully cancelled.`);
        setSubscriptionChange(subscriptioniChange + 1);
        setModalCancelHost(false);
      } else {
        toast.error(res.message);
        setModalCancelHost(false);
      }
    } catch (error) {
      toast.error(error.message);
      setModalCancelHost(false);
    }
    setClickProcessBtn(false);
  };

  const handleCancelDraw = async () => {
    setClickProcessBtn(true);
    try {
      const res = await apiClient.post(`${process.env.REACT_APP_API_URL}/transactions/stripe/cancelSubscriptionDraw`, {
        userSubscriptionID: selectDraw.userSubscriptionID,
      });
      if (res.status) {
        toast.success(`Draw subscription successfully cancelled.`);
        setSubscriptionChange(subscriptioniChange + 1);
        setModalCancelDraw(false);
      } else {
        toast.error(res.message);
        setModalCancelDraw(false);
      }
    } catch (error) {
      toast.error(error.message);
      setModalCancelDraw(false);
    }
    setClickProcessBtn(false);
  };

  const handleEditDraw = async () => {
    setClickProcessBtn(true);
    if (selectDraw.userSubscriptionQuantity === editQuantity) {
      toast.error(`Please enter the changed value.`);
      return;
    }
    if (editQuantity === "" || editQuantity < 1) {
      toast.error(`Please enter a valid value.`);
      return;
    }
    try {
      const res = await apiClient.post(`${process.env.REACT_APP_API_URL}/transactions/stripe/editSubscription`, {
        type: 2, //Draw Subscription
        userSubscriptionID: selectDraw.userSubscriptionID,
        newQuantity: editQuantity,
      });
      if (res.status) {
        toast.success(`Draw subscription successfully updated.`);
        setSubscriptionChange(subscriptioniChange + 1);
        setModalEditDraw(false);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setClickProcessBtn(false);
  };

  const handleQuantityChange = (event) => {
    event.preventDefault();
    let value = parseInt(event.target.value);
    if (isNaN(value)) {
      value = "";
    }
    setEditQuantity(value);
  };

  const showDetailsHandler = (hostID) => {
    let cloneShowDetails = { ...showDetails };
    let key = "key_" + hostID;
    cloneShowDetails[key] = !cloneShowDetails[key];
    setShowDetails(cloneShowDetails);
  };

  return (
    <>
      <Helmet>
        <title>Billing Subscriptions | Play Fund Win</title>
        <meta name="description" content="We are Play Fund Win, the #1 digital fundraising platform that makes raising money simple for sports clubs, events and charities." />
      </Helmet>
      <ToastContainer />
      <div className="px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-left">
          <h1 className="text-3xl font-bold leading-9 text-gray-900">
            <strong className="font-semibold">Subscriptions</strong>
          </h1>
        </div>
        {selectHost && <CancelHostSubscriptionModal open={modalCancelHost} setOpen={setModalCancelHost} handleCancelHost={handleCancelHost} />}
        {selectHost && selectDraw && <CancelDrawSubscriptionModal open={modalCancelDraw} setOpen={setModalCancelDraw} handleCancelDraw={handleCancelDraw} />}
        {selectHost && selectDraw && (
          <EditSubscriptionModal open={modalEditDraw} setOpen={setModalEditDraw} handleEditDraw={handleEditDraw} handleQuantityChange={handleQuantityChange} />
        )}
        {userSubscriptionList.reduce((totalActive, userSubscription) => totalActive + userSubscription.active.length, 0) === 0 ? (
          <div className="mt-8 text-center">
            <p className="text-lg text-gray-700">
              You don't have any subscriptions yet!{" "}
              <Link to="/find-a-draw" className="text-indigo-600 hover:text-indigo-900">
                Find a Draw
              </Link>
            </p>
          </div>
        ) : (
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-8 mt-8">
            {userSubscriptionList.map(
              (row) =>
                row.amount > 0 && (
                  <React.Fragment key={row.hostID}>
                    <li className="overflow-hidden rounded-lg border border-gray-200 bg-white">
                      <div className="flex items-center justify-between p-6 bg-gray-50">
                        <div className="text-sm font-medium text-gray-900">
                          <div className="flex items-center">
                            <div className="ml-4">
                              <p className="text-lg font-semibold leading-5 text-gray-900">Host: {row.hostName}</p>
                              <p className="text-sm text-gray-500">Amount: £{row.amount.toFixed(2)}</p>
                              <p className="text-sm text-gray-500">Subscription Type: {row.subscriptionType}</p>
                            </div>
                          </div>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <button
                            className="mx-2 rounded-md bg-red-600 py-2 px-4 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
                            onClick={() => {
                              setSelectHost(row);
                              setModalCancelHost(true);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="mt-3 rounded-md bg-indigo-600 py-2 px-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                            onClick={() => showDetailsHandler(row.hostID)}
                          >
                            {showDetails["key_" + row.hostID] ? "Hide details" : "View details"}
                          </button>
                        </div>
                      </div>
                      {showDetails["key_" + row.hostID] && (
                        <div className="bg-gray-50 rounded-md p-4">
                          {row.active.map((row2) => (
                            <React.Fragment key={row2.userSubscriptionID}>
                              <div className="border-b border-gray-200 py-4">
                                <div className="flex flex-col md:flex-row justify-between">
                                  <div className="flex flex-col">
                                    <p className="text-sm font-medium text-gray-900">Draw Name: {row2.drawName}</p>
                                    <p className="text-sm text-gray-500">Price: £{row2.userSubscriptionPrice.toFixed(2)}</p>
                                    <p className="text-sm text-gray-500">Quantity: {row2.userSubscriptionQuantity}</p>
                                  </div>
                                  <div className="mt-3 md:mt-0 flex gap-3 justify-end">
                                    <button
                                      className="mx-2 btn pfw-button-red rounded-md px-3 py-2 text-sm font-bold "
                                      onClick={() => {
                                        setSelectHost(row);
                                        setSelectDraw(row2);
                                        setModalCancelDraw(true);
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="btn pfw-button-green rounded-md px-3 py-2 text-sm font-bold"
                                      onClick={() => {
                                        setSelectHost(row);
                                        setSelectDraw(row2);
                                        setEditQuantity(row2.userSubscriptionQuantity);
                                        setModalEditDraw(true);
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </li>
                  </React.Fragment>
                )
            )}
          </ul>
        )}
      </div>
    </>
  );
}

export default MySubscriptions;
