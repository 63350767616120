// src/components/dashboard/MyPrizes.jsx

import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import { CheckIcon } from "@heroicons/react/20/solid";
import { DashboardContext } from "../DashboardProvider";
import { PrizeClaimModal } from "../components/PrizeClaimModal"; // Ensure this path is correct
const parser = new DOMParser();

export function MyPrizes() {
  const {
    isLoaded,
    prizeData,
    modalShow,
    setModalShow,

    editModalShow,
    setEditModalShow,
    editModalShow2,
    setEditModalShow2,
    fullname,
    setFullName,
    accountnumber,
    setAccountNumber,
    emailUser,
    setEmailUser,
    sortcode,
    setSortCode,
    paypalemail,
    setPaypalEmail,
    bankaccount,
    setBankAccount,
    prizeID,
    setPrizeID,
    paypal,
    setPaypal,
    address1,
    setAddress1,
    address2,
    setAddress2,
    postcode,
    setPostcode,
    userSurname,
    setUserSurname,
    userFirstName,
    setUserFirstName,
    ClaimCashPrize,
    ClaimNonCashPrize,
    editClaimCashPrizeHandler,
    editClaimNonCashPrizeHandler,
    isValidEmail,
  } = useContext(DashboardContext);
  let ClaimPrize;

  return (
    <>
      <Helmet>
        <title>Prizes Won | Play Fund Win</title>
        <meta name="description" content="We are Play Fund Win, the #1 digital fundraising platform that makes raising money simple for sports clubs, events, and charities." />
      </Helmet>
      <div className="px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-left">
          <h1 className="text-3xl font-bold leading-9 text-gray-900">
            <strong className="font-semibold">My Prizes</strong>
          </h1>
        </div>
        {prizeData.length === 0 ? (
          <div className="mt-8 text-center">
            <p className="text-lg text-gray-700">
              You haven't won any prizes yet!{" "}
              {/* <Link to="/find-a-draw" className="text-indigo-600 hover:text-indigo-900">
                Enter A Draw
              </Link> */}
            </p>
          </div>
        ) : (
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-8 mt-8">
            {prizeData.map((item) => (
              <li key={item.prizeID} className="overflow-hidden rounded-lg border border-gray-200 bg-white">
                <div className="flex items-center justify-between p-6 bg-gray-50">
                  <div className="text-sm font-medium text-gray-900">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <p
                          className="text-lg font-semibold leading-5 text-gray-900"
                          dangerouslySetInnerHTML={{ __html: parser.parseFromString(`${item.prizeName}`, "text/html").body.textContent }}
                        ></p>
                        <div className="flex items-center">
                          <CheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                          <p className="ml-2 text-sm text-gray-500">Prize Won</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    {item.drawPrizeState === 1 ? (
                      item.prizeTypeID === 1 || item.prizeTypeID === 2 ? (
                        <button
                          onClick={() => {
                            setModalShow(true);
                            ClaimPrize = ClaimCashPrize;
                            setPrizeID(item.prizeID);
                          }}
                          className="rounded-md bg-indigo-600 py-2 px-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                        >
                          Claim Prize
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setModalShow(true);
                            ClaimPrize = ClaimNonCashPrize;

                            setPrizeID(item.prizeID);
                          }}
                          className="rounded-md bg-indigo-600 py-2 px-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                        >
                          Claim Prize
                        </button>
                      )
                    ) : item.drawPrizeState === 2 ? (
                      <div>
                        <span className="inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800">Prize Already Claimed</span>
                        <button
                          onClick={() => {
                            if (item.prizeClaim) {
                              setAccountNumber(item.prizeClaim.account_no === "paypal" ? "-" : item.prizeClaim.account_no);
                              setSortCode(item.prizeClaim.Sortcode === "paypal" ? "-" : item.prizeClaim.Sortcode);
                              setFullName(item.prizeClaim.full_name === "paypal" ? "-" : item.prizeClaim.full_name);
                              setPaypalEmail(item.prizeClaim.email === "bank" ? "-" : item.prizeClaim.email);
                            }
                            setModalShow(true);
                            setPrizeID(item.prizeID);
                          }}
                          className="m-2 rounded-md bg-indigo-600 py-2 px-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                        >
                          Edit Bank Details
                        </button>
                      </div>
                    ) : item.drawPrizeState === 3 ? (
                      <span className="inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800">Prize Already Dispatched</span>
                    ) : null}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Modals */}
      <PrizeClaimModal
        open={modalShow}
        setOpen={setModalShow}
        bankaccount={bankaccount}
        setBankAccount={setBankAccount}
        paypal={paypal}
        setPaypal={setPaypal}
        fullname={fullname}
        setFullName={setFullName}
        accountnumber={accountnumber}
        setAccountNumber={setAccountNumber}
        sortcode={sortcode}
        setSortCode={setSortCode}
        paypalemail={paypalemail}
        setPaypalEmail={setPaypalEmail}
        ClaimPrize={ClaimPrize}
      />
      <ToastContainer />
    </>
  );
}

export default MyPrizes;
