import React from "react";
import { Helmet } from "react-helmet";
import { useSiteData } from "../context/SiteDataProvider";
import LoginForm from "../components/forms/LoginForm";

export default function Login() {
  const { siteContent } = useSiteData();
  if (!siteContent || !siteContent.site) {
    return <div>Loading...</div>;
  }
  const {
    site: { siteName, siteURL },
    pages
  } = siteContent;
  return (
    <>
      <Helmet>
        <title>Login to Your {siteName} Account | Get Closer to Big Wins</title>
        <meta
          name="description"
          content="Access your account to manage your tickets, view upcoming draws, and keep track of your winnings. Your next big win is just a login away!"
        />
      </Helmet>
      <div className="bg-white" style={{ height: "100vh" }}>
        <LoginForm />
      </div>
    </>
  );
}
