// components/dashboard/DashboardHome.jsx
import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "../DashboardProvider";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useSiteData } from "../../../context/SiteDataProvider";

const DashboardHome = () => {
  const { isLoggedIn, userFirstName } = useSiteData();
  const { prizeData, userSubscriptionList, setPage } = useContext(DashboardContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8">
      <div className="text-left">
        <h1 className="text-3xl font-bold leading-9 text-gray-900">
          <strong className="font-semibold">Welcome, {userFirstName}!</strong>
        </h1>
      </div>
      <div className="mt-8">
        <h2 className="text-2xl font-semibold text-gray-900">Account Overview</h2>
        <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-8">
          {/* Prizes Section */}
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900">Your Prizes</h3>
            {prizeData.length === 0 ? (
              <p className="mt-2 text-gray-700">You haven't won any prizes yet!</p>
            ) : (
              <ul className="mt-2">
                {prizeData.slice(0, 3).map((item) => (
                  <li key={item.prizeID} className="flex items-center justify-between p-4 border-b border-gray-200">
                    <div>
                      <p className="text-sm font-medium text-gray-900">{item.prizeName}</p>
                      <div className="flex items-center mt-1">
                        <CheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                        <p className="ml-2 text-sm text-gray-500">Prize Won</p>
                      </div>
                    </div>
                    <button onClick={() => setPage(3)} className="text-indigo-600 hover:text-indigo-900">
                      View
                    </button>
                  </li>
                ))}
              </ul>
            )}
            <button
              onClick={() => setPage(3)}
              className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700"
            >
              View All Prizes
            </button>
          </div>

          {/* Subscriptions Section */}
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900">Your Subscriptions</h3>
            {userSubscriptionList.reduce((totalActive, userSubscription) => totalActive + userSubscription.active.length, 0) === 0 ? (
              <p className="mt-2 text-gray-700">You don't have any subscriptions yet!</p>
            ) : (
              <ul className="mt-2">
                {userSubscriptionList.map((row) =>
                  row.active.slice(0, 3).map((sub) => (
                    <li key={sub.userSubscriptionID} className="flex items-center justify-between p-4 border-b border-gray-200">
                      <div>
                        <p className="text-sm font-medium text-gray-900">{sub.drawName}</p>
                        <p className="mt-1 text-sm text-gray-500">Quantity: {sub.userSubscriptionQuantity}</p>
                        <p className="mt-1 text-sm text-gray-500">Price: £{sub.userSubscriptionPrice.toFixed(2)}</p>
                      </div>
                      <button onClick={() => setPage(4)} className="text-indigo-600 hover:text-indigo-900">
                        View
                      </button>
                    </li>
                  ))
                )}
              </ul>
            )}
            <button
              onClick={() => setPage(4)}
              className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700"
            >
              View All Subscriptions
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
