// signup-new.jsx
import React from "react";
import { Helmet } from "react-helmet";
import { useSiteData } from "../context/SiteDataProvider"; // Adjust the path as needed
import SignupForm from "../components/forms/SignupForm"; // Adjust the path as needed
import CheckoutForm from "../components/forms/CheckoutForm"; // Adjust the path as needed
// import SubscriptionPlanForm from "../components/SubscriptionPlanForm"; // Adjust the path as needed

const Signup = () => {
  const { page, setPage } = useSiteData();
  const renderForm = () => {
    // setPage(1);
    switch (page) {
      case 1:
        return <SignupForm setPage={setPage} />;
      case 2:
        return <CheckoutForm setPage={setPage} />;
      case 3:
        // return <SubscriptionPlanForm />;
        break;
      default:
        return <SignupForm setPage={setPage} />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Signup for Mission Impossible Draw | Your Gateway to Exciting Prizes</title>
        <meta
          name="description"
          content="Enter the Mission Impossible Draw to witness Tom Cruise's stunts on set, meet the cast, and tour Pinewood Studios. Enter now for a Hollywood adventure!"
        />
      </Helmet>
      <div className="bg-white" style={{ height: "100vh" }}>
        {renderForm()}
      </div>
    </>
  );
};

export default Signup;
