// src/components/dashboard/UserTickets.jsx
import React, { useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
// import secureLocalStorage from "react-secure-storage";
import { DashboardContext } from "../DashboardProvider";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={`${className} block text-white`} style={{ ...style, display: "block" }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={`${className} block text-white`} style={{ ...style, display: "block" }} onClick={onClick} />;
}

export function UserTickets() {
  const APIURL = process.env.REACT_APP_API_URL;
  const { userData, siteID, setPage } = useContext(DashboardContext);
  const [purchaseData, setPurchaseData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  var settings = {
    dots: false,
    infinite: true,
    limit: 7,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    const fetchPurchaseData = async (userID) => {
      try {
        const response = await axios.get(`${APIURL}/users/${userID}/data/${siteID}`, {
          withCredentials: true,
          credentials: "same-origin",
        });
        const data = response.data.content;
        data.drawEntries.forEach((element) => {
          element.transactionEndDate = data.transactions
            .filter((el) => el.transactionDrawEntriesID === element.drawEntryID)
            .map((e) => e.transactionDateTime)
            .join();
          element.transactionStatus = data.transactions
            .filter((el) => el.transactionDrawEntriesID === element.drawEntryID)
            .map((e) => e.transactionStatus)
            .join();
        });
        setIsLoaded(true);
        setPurchaseData(data.drawEntries);
      } catch (error) {
        console.error("Error fetching purchase data", error);
      }
    };

    console.log("UserTickets useEffect");
    console.log(userData);
    console.log(userData.userID);

    if (userData && userData.userID) {
      fetchPurchaseData(userData.userID);
    }
  }, [userData, siteID, APIURL]);

  return (
    <>
      {isLoaded ? (
        <div className="px-4 sm:px-6 lg:px-8 py-8">
          <div className="text-left">
            <h1 className="text-3xl font-bold leading-9 text-gray-900">
              <strong className="font-semibold">My Active Tickets</strong>
            </h1>
          </div>
          {purchaseData.length > 2 ? (
            <Slider {...settings}>
              {purchaseData.map((p) => (
                <div key={p.drawEntryID}>
                  <div className="ticket-slide text-start shadow-md p-4 m-3 bg-gray-50 border border-gray-200 rounded-md p-4">
                    <h4 className="text-base border-b pb-4 mb-4">{p.drawName}</h4>
                    <div className="flex justify-between">
                      <div className="flex flex-col">
                        <span className="text-gray-500">Total Entries</span>
                        <span className="text-lg font-semibold">{p.drawEntries}</span>
                      </div>
                      <div className="flex flex-col border-l border-gray-200 pl-3">
                        <span className="text-gray-500">Total Entry Price</span>
                        <span className="text-lg font-semibold">£{p.drawEntryTotal}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : purchaseData.length > 0 ? (
            purchaseData.map((p) => (
              <div key={p.drawEntryID} className="text-center flex justify-center">
                <div className="ticket-slide text-start shadow-md p-4 m-3bg-gray-50 border border-gray-200 rounded-md w-full sm:w-2/3 md:w-1/3">
                  <h4 className="text-base border-b pb-4 mb-4">{p.drawName}</h4>
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="text-gray-500">Total Entries</span>
                      <span className="text-lg font-semibold">{p.drawEntries}</span>
                    </div>
                    <div className="flex flex-col border-l border-gray-200 pl-3">
                      <span className="text-gray-500">Total Entry Price</span>
                      <span className="text-lg font-semibold">£{p.drawEntryTotal}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="mt-8 text-center">
              <p className="text-lg text-gray-700">
                You are not participating in live draws.{" "}
                <Link className="text-indigo-600 hover:text-indigo-900" to="/find-a-draw">
                  Find a draw
                </Link>
              </p>
            </div>
          )}
          <div className="text-center mt-8">
            <Link onClick={() => setPage(5)} className="bg-indigo-600 text-white py-2 px-4 rounded-md font-semibold hover:bg-indigo-500">
              View All Purchase History
            </Link>
          </div>
        </div>
      ) : (
        <p className="text-center mb-0">Loading Data...</p>
      )}
    </>
  );
}

export default UserTickets;
