// src/components/dashboard/components/PrizeClaimModal.jsx
import { Fragment } from "react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";

export const PrizeClaimModal = ({
  open,
  setOpen,
  bankaccount,
  setBankAccount,
  paypal,
  setPaypal,
  fullname,
  setFullName,
  accountnumber,
  setAccountNumber,
  sortcode,
  setSortCode,
  paypalemail,
  setPaypalEmail,
  ClaimPrize,
}) => (
  <Transition show={open} as={Fragment}>
    <Dialog as="div" className="relative z-50" onClose={() => setOpen(false)}>
      <TransitionChild
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />
      </TransitionChild>

      <div className="fixed inset-0 flex items-center justify-center p-4 text-center sm:block sm:p-0">
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Please Confirm the bank details</h3>
              <div className="mt-2">
                <div className="flex gap-2 mb-3">
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      setBankAccount(true);
                      setPaypal(false);
                    }}
                    className={`w-1/2 border rounded-md py-2 px-4 text-sm font-medium ${bankaccount ? "bg-indigo-600 text-white" : "bg-white text-gray-500"}`}
                  >
                    Bank Account
                  </button>
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      setBankAccount(false);
                      setPaypal(true);
                    }}
                    className={`w-1/2 border rounded-md py-2 px-4 text-sm font-medium ${paypal ? "bg-indigo-600 text-white" : "bg-white text-gray-500"}`}
                  >
                    PayPal
                  </button>
                </div>
                <div className={bankaccount ? "block" : "hidden"}>
                  <input
                    type="text"
                    value={fullname}
                    onChange={(event) => setFullName(event.target.value)}
                    placeholder="Full Name"
                    className="w-full rounded-md border-gray-300 shadow-sm mb-3"
                  />
                  <input
                    type="text"
                    value={accountnumber}
                    onChange={(event) => setAccountNumber(event.target.value)}
                    placeholder="Account Number"
                    className="w-full rounded-md border-gray-300 shadow-sm mb-3"
                  />
                  <input
                    type="text"
                    value={sortcode}
                    onChange={(event) => setSortCode(event.target.value)}
                    placeholder="Sort Code"
                    className="w-full rounded-md border-gray-300 shadow-sm"
                  />
                  <button
                    onClick={ClaimPrize}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:text-sm"
                  >
                    Submit
                  </button>
                </div>
                <div className={paypal ? "block" : "hidden"}>
                  <input
                    type="email"
                    value={paypalemail}
                    onChange={(event) => setPaypalEmail(event.target.value)}
                    placeholder="Email Address"
                    className="w-full rounded-md border-gray-300 shadow-sm"
                  />
                  <button
                    onClick={ClaimPrize}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:text-sm"
                  >
                    Submit
                  </button>
                </div>
                <div className="mt-3 text-center">
                  <img src={require("../../../images/padlock.png")} alt="Trusted Secure" className="inline-block w-5 h-5" />
                  <span className="ml-2 text-sm font-medium text-gray-700">Trusted Secure</span>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </TransitionChild>
      </div>
    </Dialog>
  </Transition>
);

export default PrizeClaimModal;
