// components/dashboard/DashboardPage1.jsx
import React from "react";
import { ChangePasswordForm } from "../../forms/ChangePasswordForm";
const ChangePassword = () => {
  return (
    <>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <div className="text-left pb-4">
            <h1 className="text-3xl font-bold leading-9 text-gray-900">
              <strong className="font-semibold">My Password</strong>
            </h1>
          </div>
          <h2 className="text-lg font-semibold leading-6 text-gray-900">Change Password</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Ensure it's at least 8 characters OR at least 8 characters including a number and a lowercase letter.</p>
          <ChangePasswordForm />

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                First name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  //   value={userFirstName}
                  //   onChange={(event) => setFirstName(event.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add content for Dashboard Page 1 */}
    </>
  );
};

export default ChangePassword;
