import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import SiteRouter from "./site-router.js";
import "./fonts/Saints-Regular.woff2";
import "react-toastify/dist/ReactToastify.css";
import "./fonts/Saints-Bold.woff2";
import { SiteDataProvider } from "./context/SiteDataProvider.jsx";

const APIURL = process.env.REACT_APP_API_URL || "https://api.playfundwin.com";
const siteID = process.env.REACT_APP_SITE_ID || "12345";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SiteDataProvider APIURL={APIURL} siteID={siteID}>
      <SiteRouter />
    </SiteDataProvider>
  </React.StrictMode>
);
