import { useState, useContext, createContext, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DrawDataSingleton from "./DrawDataSingleton";
import SiteContentDataSingleton from "./SiteContentDataSingleton";
import secureLocalStorage from "react-secure-storage";
import PropTypes from "prop-types";

const SiteDataContext = createContext();

function SiteDataProvider({ children, APIURL, siteID }) {
  // Core State Management
  const [siteContent, setSiteContent] = useState({}); // [siteContent, setSiteContent]
  const [userData, setUserData] = useState({});
  const [siteDrawData, setsiteDrawData] = useState(null);
  const [userID, setUserID] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false); // [true, false]
  const [remainLoggedIn, setRemainLoggedIn] = useState(false); // [true, false]
  // User Data State Management
  const [step, setStep] = useState(""); // ["signup", "checkout"]
  const [email, setEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userSurname, setUserSurname] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [age, setAge] = useState(0);
  const [SendDate, setSendDate] = useState();
  // Signup State Management
  const [gender, setGender] = useState(1);
  const [fullName, setFullName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("+44 ");
  const [amount, setAmount] = useState(10);
  const [entries, setEntries] = useState(10);
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [consent3, setConsent3] = useState(false);
  const [oneShow, setOneShow] = useState(false);
  const [isStripe, setStripe] = useState(false);
  const [isOneOff, setIsOneOff] = useState(true);
  // Signup Page State Management
  const [page, setPage] = useState("1");
  const [returnPage, setReturnPage] = useState("");

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function hideModal() {
    setOneShow(false);
  }
  useEffect(() => {
    async function fetchData() {
      console.log("SiteDataProvider Initialised");
      const userData = secureLocalStorage.getItem("UserData");
      if (userData) {
        let userDetails;
        try {
          userDetails = JSON.parse(userData);
        } catch (error) {
          userDetails = userData;
        }
        if (userDetails && userDetails.userID && userDetails.userData) {
          setIsLoggedIn(true);
          setUserID(userDetails.userID);
          setUserFirstName(userDetails.userData.userFirstName);
          setUserSurname(userDetails.userData.userSurname);
        } else {
          setIsLoggedIn(false);
        }
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchDrawData() {
      const drawDataSingleton = new DrawDataSingleton(APIURL, siteID);
      const drawData = await drawDataSingleton.fetchDrawData();
      setsiteDrawData(drawData);
    }

    fetchDrawData();
  }, [APIURL, siteID]);

  useEffect(() => {
    async function fetchSiteContentData() {
      const siteContentDataSingleton = new SiteContentDataSingleton(APIURL, siteID);
      const siteContentData = await siteContentDataSingleton.fetchSiteContentData();
      console.log("Site Content Data:", siteContentData);
      setSiteContent(siteContentData);
    }

    fetchSiteContentData();
  }, [APIURL, siteID]);

  return (
    <SiteDataContext.Provider
      value={{
        siteContent,
        setSiteContent,
        userData,
        setUserData,
        siteDrawData,
        setsiteDrawData,
        userID,
        setUserID,
        isLoggedIn,
        setIsLoggedIn,
        remainLoggedIn,
        setRemainLoggedIn,
        step,
        setStep,
        page,
        setPage,
        returnPage,
        setReturnPage,
        password,
        setPassword,
        email,
        setEmail,
        userFirstName,
        setUserFirstName,
        userSurname,
        setUserSurname,
        dob,
        setDob,
        age,
        setAge,
        SendDate,
        setSendDate,
        gender,
        setGender,
        fullName,
        setFullName,
        userPhoneNumber,
        setUserPhoneNumber,
        amount,
        setAmount,
        entries,
        setEntries,
        consent1,
        setConsent1,
        consent2,
        setConsent2,
        consent3,
        setConsent3,
        oneShow,
        setOneShow,
        isStripe,
        setStripe,
        isOneOff,
        setIsOneOff,
        stripePromise,
        timeout,
        hideModal
      }}
    >
      <Elements stripe={stripePromise}>{children}</Elements>
    </SiteDataContext.Provider>
  );
}
SiteDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
  APIURL: PropTypes.string.isRequired,
  siteID: PropTypes.string.isRequired
};

const useSiteData = () => useContext(SiteDataContext);
export default SiteDataProvider;
export { SiteDataProvider, SiteDataContext, useSiteData };
