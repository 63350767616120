import { Fragment, useState } from "react";
import { Dialog, Transition, TransitionChild, Disclosure } from "@headlessui/react";
import { ChevronRightIcon, Bars3Icon, CreditCardIcon, GiftIcon, ClockIcon, HomeIcon, UsersIcon, TicketIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Logo } from "../Logo";
import { useDashboard } from "./DashboardProvider";

const navigation = [
  { name: "Dashboard", icon: HomeIcon, page: 1 },
  { name: "My Tickets", icon: TicketIcon, page: 2 },
  { name: "My Prizes", icon: GiftIcon, page: 3 },
  { name: "My Subscription", icon: ClockIcon, page: 4 },
  {
    name: "My Account",
    icon: UsersIcon,
    page: null,
    children: [
      { name: "My Payments", icon: CreditCardIcon, page: 5 },
      { name: "Settings", page: 6 },
      { name: "Change Password", page: 7 }
    ]
  }
  // { name: "Change Password", icon: LockClosedIcon, page: 6 },
];

const teams = [
  { id: 1, name: "Heroicons", initial: "H" },
  { id: 2, name: "Tailwind Labs", initial: "T" },
  { id: 3, name: "Workcation", initial: "W" }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardSidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { page, setPage, userSurname, userFirstName, logout } = useDashboard();

  const handleNavigation = page => {
    setPage(page);
    setSidebarOpen(false);
  };

  return (
    <>
      <Transition show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>

          <div className="fixed inset-0 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <a href="/" className="-m-1.5 p-1.5">
                      <Logo className="h-8 w-auto" />
                    </a>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map(item => (
                            <li key={item.name}>
                              {!item.children ? (
                                <a
                                  href="#"
                                  onClick={() => handleNavigation(item.page)}
                                  className={classNames(
                                    item.page === page ? "bg-gray-50 text-indigo-600" : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                    "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(item.page === page ? "text-indigo-600" : "text-gray-400 group-hover:text-indigo-600", "h-6 w-6 shrink-0")}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              ) : (
                                <Disclosure as="div" key={item.name}>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button
                                        className={classNames(
                                          item.page === page ? "bg-gray-50 text-indigo-600" : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                          "flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6"
                                        )}
                                      >
                                        <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                        {item.name}
                                        <ChevronRightIcon
                                          className={classNames(open ? "rotate-90 text-gray-500" : "text-gray-400", "ml-auto h-5 w-5 shrink-0")}
                                          aria-hidden="true"
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel as="ul" className="mt-1 px-2">
                                        {item.children.map(subItem => (
                                          <li key={subItem.name}>
                                            <Disclosure.Button
                                              as="a"
                                              href="#"
                                              onClick={() => handleNavigation(subItem.page)}
                                              className={classNames(
                                                subItem.page === page ? "bg-gray-50" : "hover:bg-gray-50",
                                                "block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700"
                                              )}
                                            >
                                              {subItem.name}
                                            </Disclosure.Button>
                                          </li>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              )}
                            </li>
                          ))}
                        </ul>
                      </li>
                      {/* <li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                        <ul role="list" className="-mx-2 mt-2 space-y-1">
                          {teams.map((team) => (
                            <li key={team.name}>
                              <a
                                href="#"
                                className={classNames(
                                  team.current ? "bg-gray-50 text-indigo-600" : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                  "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                )}
                              >
                                <span
                                  className={classNames(
                                    team.current ? "border-indigo-600 text-indigo-600" : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                                    "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium"
                                  )}
                                >
                                  {team.initial}
                                </span>
                                <span className="truncate">{team.name}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li> */}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <div className="flex h-16 shrink-0 items-center">
            <a href="/" className="-m-1.5 p-1.5">
              <Logo className="h-8 w-auto" />
            </a>{" "}
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map(item => (
                    <li key={item.name}>
                      {!item.children ? (
                        <a
                          href="#"
                          onClick={() => handleNavigation(item.page)}
                          className={classNames(
                            item.page === page ? "bg-gray-50 text-indigo-600" : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                            "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                          )}
                        >
                          <item.icon
                            className={classNames(item.page === page ? "text-indigo-600" : "text-gray-400 group-hover:text-indigo-600", "h-6 w-6 shrink-0")}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ) : (
                        <Disclosure as="div" key={item.name}>
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  item.page === page ? "bg-gray-50 text-indigo-600" : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                  "flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6"
                                )}
                              >
                                <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                {item.name}
                                <ChevronRightIcon className={classNames(open ? "rotate-90 text-gray-500" : "text-gray-400", "ml-auto h-5 w-5 shrink-0")} aria-hidden="true" />
                              </Disclosure.Button>
                              <Disclosure.Panel as="ul" className="mt-1 px-2">
                                {item.children.map(subItem => (
                                  <li key={subItem.name}>
                                    <Disclosure.Button
                                      as="a"
                                      href="#"
                                      onClick={() => handleNavigation(subItem.page)}
                                      className={classNames(
                                        subItem.page === page ? "bg-gray-50" : "hover:bg-gray-50",
                                        "block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700"
                                      )}
                                    >
                                      {subItem.name}
                                    </Disclosure.Button>
                                  </li>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
              {/* <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {teams.map((team) => (
                    <li key={team.name}>
                      <a
                        href="#"
                        className={classNames(
                          team.current ? "bg-gray-50 text-indigo-600" : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                          "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                        )}
                      >
                        <span
                          className={classNames(
                            team.current ? "border-indigo-600 text-indigo-600" : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                            "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium"
                          )}
                        >
                          {team.initial}
                        </span>
                        <span className="truncate">{team.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </li> */}
              <li className="-mx-6 mt-auto">
                <a href="#" className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                  <img className="h-8 w-8 rounded-full bg-gray-50" src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" alt="" />
                  <span className="sr-only">Your profile</span>
                  <span aria-hidden="true">
                    {userFirstName} {userSurname}
                  </span>
                </a>
              </li>
            </ul>
            <button type="button" className="bg-indigo-600 text-gray-400 rounded-md my-2 p-2 hover:text-gray-500" onClick={logout}>
              Logout
            </button>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">Dashboard</div>
        <a href="#">
          <span className="sr-only">Your profile</span>
          <img className="h-8 w-8 rounded-full bg-gray-50" src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" alt="" />
        </a>
      </div>
    </>
  );
}
