import secureLocalStorage from "react-secure-storage";

import siteContentData from "../siteContent.json";
// import apiClient from "../api.config"
const SITE_CONTENT_KEY = "siteContentData";
const EXPIRY_KEY = "siteContentExpiry";

class SiteContentDataSingleton {
  static instance;

  constructor() {
    if (SiteContentDataSingleton.instance) {
      return SiteContentDataSingleton.instance;
    }

    SiteContentDataSingleton.instance = this;
  }

  async fetchSiteContentData() {
    const data = secureLocalStorage.getItem(SITE_CONTENT_KEY);
    const expiry = secureLocalStorage.getItem(EXPIRY_KEY);
    let parsedData = null;

    try {
      parsedData = JSON.parse(data); // Attempt to parse data
    } catch (error) {
      console.error("Error parsing site content data:", error);
    }

    const isEmptyObject = parsedData && Object.keys(parsedData).length === 0 && parsedData.constructor === Object;

    if (parsedData && expiry && new Date(expiry) > new Date() && !isEmptyObject) {
      return parsedData;
    } else {
      try {
        const newData = siteContentData; // Directly read the local JSON file
        secureLocalStorage.setItem(SITE_CONTENT_KEY, JSON.stringify(newData));
        secureLocalStorage.setItem(EXPIRY_KEY, new Date(Date.now() + 0.25 * 60 * 1000).toISOString());
        return newData;
      } catch (error) {
        console.error("Failed to fetch site content data:", error);
        return null;
      }
    }
  }
}

export default SiteContentDataSingleton;
