import { Fragment } from "react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";

export default function EditCardModal({
  open,
  setOpen,
  cardNumber,
  handleCardNumberChange,
  cardBrandLogo,
  cardExpiryDate,
  handleCardExpiryDateChange,
  cardCVC,
  handleCardCVCChange,
  handleEditUserPaymentMethod,
  clickPaymentMethodBtn,
}) {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Edit Card
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="mt-5">
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700">
                            Card Number
                          </label>
                          <input
                            type="text"
                            name="cardNumber"
                            id="cardNumber"
                            className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                            placeholder="XXXX XXXX XXXX XXXX"
                            maxLength={19}
                            value={cardNumber}
                            onChange={handleCardNumberChange}
                          />
                          {cardBrandLogo && (
                            <img
                              src={require(`../../../images/payment-logos/${cardBrandLogo}-logo.png`)}
                              className="absolute inset-y-0 right-0 pr-3 flex items-center"
                              style={{ width: "50px" }}
                              alt="Card Brand Logo"
                            />
                          )}
                        </div>
                        <div className="mt-6 grid grid-cols-2 gap-6">
                          <div>
                            <label htmlFor="cardExpiryDate" className="block text-sm font-medium text-gray-700">
                              Expiry Date
                            </label>
                            <input
                              type="text"
                              name="cardExpiryDate"
                              id="cardExpiryDate"
                              className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                              placeholder="MM/YY"
                              maxLength={5}
                              value={cardExpiryDate}
                              onChange={handleCardExpiryDateChange}
                            />
                          </div>
                          <div>
                            <label htmlFor="cardCVC" className="block text-sm font-medium text-gray-700">
                              CVC/CVV
                            </label>
                            <input
                              type="text"
                              name="cardCVC"
                              id="cardCVC"
                              className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                              placeholder="XXX"
                              maxLength={4}
                              value={cardCVC}
                              onChange={handleCardCVCChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleEditUserPaymentMethod}
                    disabled={clickPaymentMethodBtn}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
