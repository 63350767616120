import React, { useState, useEffect } from "react";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import Moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

export function PurchaseHistory() {
  const [login, setIsLoggedIn] = useState(secureLocalStorage.getItem("UserData") ? secureLocalStorage.getItem("UserData").loggedin : false);
  const [PurchaseData, SetPurchaseData] = useState([]);
  const [PurchaseDataDefault, SetPurchaseDataDefault] = useState([]);
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("UserData");
  let APIURL = process.env.REACT_APP_API_URL;
  let SITE_ID = process.env.REACT_APP_API_SITE_ID;
  const [search, setSearch] = useState("");
  const [displayDataActive, SetDisplayData] = useState([]);
  const [isLoaded, setisLoaded] = useState(false);
  const status = ["", "AWAITING_PAYMENT", "PROCESSING", "PAID", "FAILED", "CANCELLED", "REFUND_PARTIAL", "REFUND_FULL", "PRIZE_PAYOUT"];
  const colorHex = ["", "#e64662", "#e64662", "#59b8b2", "#e64662", "#e64662", "#4a4a4a", "#4a4a4a", "#59b8b2"];
  const entrytype = ["", "One-Off", "Recurring", "Subscription"];

  const itemsPerPage = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  let userDetail = secureLocalStorage.getItem("UserData");

  useEffect(() => {
    if (!login) {
      window.location.replace("/login");
    }
    fetchPurchaseData(user.userID);
  }, []);

  function fetchPurchaseData(userID) {
    axios
      .get(`${APIURL}/users/${userID}/data/${SITE_ID}`, {
        withCredentials: true,
        credentials: "same-origin",
      })
      .then((res) => {
        res.data.content.drawEntries.forEach((element) => {
          element.transactionEndDate = res.data.content.transactions
            .filter((el) => el.transactionDrawEntriesID === element.drawEntryID)
            .map((e) => e.transactionDateTime)
            .join();
          element.transactionStatus = res.data.content.transactions
            .filter((el) => el.transactionDrawEntriesID === element.drawEntryID)
            .map((e) => e.transactionStatus)
            .join();
        });
        setisLoaded(true);
        SetPurchaseData(res.data.content.drawEntries);
        SetDisplayData(res.data.content.drawEntries);
        SetPurchaseDataDefault(res.data.content.drawEntries);
      });
  }

  useEffect(() => {
    if (search.trim().length >= 1 || search.trim().length === 0) {
      SetDisplayData(
        PurchaseData.filter((item) => {
          if (item.drawName.toLowerCase().includes(search.toLowerCase())) {
            return item;
          }
        })
      );
    }
  }, [search, PurchaseData]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedData = displayDataActive.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>
      <Helmet>
        <title>Purchase History | Play Fund Win</title>
        <meta name="description" content="We are Play Fund Win, the #1 digital fundraising platform that makes raising money simple for sports clubs, events, and charities." />
      </Helmet>
      <ToastContainer />
      {userDetail && (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">Purchase History</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all your purchase history including the draw name, purchased date and time, ticket validity, entry type, total entries, total price, and status.
              </p>
            </div>
            {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <Link to="/find-a-draw" className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
                Find a Draw
              </Link>
            </div> */}
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {isLoaded ? (
                  PurchaseData.length > 0 ? (
                    <>
                      <div className="flex justify-end mb-4">
                        <input
                          type="search"
                          className="search-field bg-gray-200 p-2 rounded-lg w-80"
                          placeholder="Search"
                          onChange={(e) => setSearch(e.target.value)}
                          value={search}
                        />
                      </div>
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Draw Name</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Purchased Date & Time</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Ticket Valid Till</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Entry Type</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total Entries</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total Price</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {paginatedData.map((item, index) => (
                            <tr key={item.id + index}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{item.drawName}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{Moment(item.transactionEndDate).format("LLL")}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{Moment(item.drawEntryValidToDate).format("LLL")}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{entrytype[item.drawEntryType]}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.drawEntries}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.drawEntryTotal}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500" style={{ color: colorHex[item.transactionStatus] }}>
                                {status[item.transactionStatus]}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {/* Pagination */}
                      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                        <div className="flex flex-1 justify-between sm:hidden">
                          <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === Math.ceil(displayDataActive.length / itemsPerPage)}
                            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                          >
                            Next
                          </button>
                        </div>
                        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                          <div>
                            <p className="text-sm text-gray-700">
                              Showing <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> to{" "}
                              <span className="font-medium">{Math.min(currentPage * itemsPerPage, displayDataActive.length)}</span> of{" "}
                              <span className="font-medium">{displayDataActive.length}</span> results
                            </p>
                          </div>
                          <div>
                            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                              <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                              >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                              {[...Array(Math.ceil(displayDataActive.length / itemsPerPage)).keys()].map((page) => (
                                <button
                                  key={page + 1}
                                  onClick={() => handlePageChange(page + 1)}
                                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                                    currentPage === page + 1
                                      ? "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                  }`}
                                >
                                  {page + 1}
                                </button>
                              ))}
                              <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === Math.ceil(displayDataActive.length / itemsPerPage)}
                                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                              >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="text-center">
                      <p className="text-lg">You are not participating in live draws!</p>
                      <Link to="/find-a-draw" className="inline-block mt-4 bg-red-500 text-white px-6 py-2 rounded-lg">
                        Find a Draw
                      </Link>
                    </div>
                  )
                ) : (
                  <p className="text-center text-lg">Loading Data...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default PurchaseHistory;
